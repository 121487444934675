import './assets/less/bootstrap.less';
import 'font-awesome/css/font-awesome.css';
import './styles/fonts.css';
import './styles/client.css';

import '@babel/polyfill';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import App from './Login';
import VueRouter from 'vue-router';

Vue.use(VueI18n);
Vue.use(VueRouter);

Vue.config.lang = window._STATE.lang || 'ru';
const i18n = new VueI18n({
  locale: window._STATE.lang,
  messages: { [window._STATE.lang]: window._STATE.translations },
});
new Vue({
  i18n,
  el: '#app',
  render: h => h(App),
});
