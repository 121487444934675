import loader from '../helpers/loader';

export default {
  async isEmailRegistered(email)
  {
    return await loader.postJSON('/register/is-free-email', { email: email });
  },
  async isEmailDisposable(email)
  {
    return await loader.postJSON('/register/is-disposable-email', { email: email });
  },
  async getCountries()
  {
    return await loader.getJSON('/register/countries');
  },
  async findRegions(countryCode, name)
  {
    name = encodeURIComponent(name)
    return await loader.getJSON(`/register/${countryCode}/regions?name=${name}`);
  },
  async findLocations(countryCode, region, location)
  {
    region = encodeURIComponent(region);
    location = encodeURIComponent(location);
    return await loader.getJSON(`/register/${countryCode}/locations?region=${region}&location=${location}`);
  },
  async findInstitutions(countryCode, search)
  {
    search = encodeURIComponent(search);
    return await loader.getJSON(`/register/${countryCode}/institutions?search=${search}`);
  },
  async getInstitution(id)
  {
    return await loader.getJSON(`/register/institutions/${id}`);
  },
  async findTimezones(countryCode)
  {
    return await loader.getJSON(`/register/${countryCode}/timezones`);
  },
  async register(data)
  {
    return await loader.postJSON('/register', data);
  },
  async getGlobalFields()
  {
    return await loader.getJSON('/register/global-fields');
  }
}
