<template>
  <div class="login-page">
    <div id="app">
      <main-menu ref="menu"></main-menu>
      <!-- <div class="login-container"> -->
      <div class="container login-container">
        <div class="logo-container">
          <a href="/" class="logo"><img :src="logoPath" alt="ITMO"></a>
        </div>
        <form v-if="!isSent && !needRec" class="col-md-6 col-md-offset-3 login" method="post" @submit="isSubmitting = true">
          <div class="alert alert-danger" v-if="isIncorrect">{{ $t('signIn.wrongCredentials') }}</div>
          <div>
            <div class="login-header" v-if="!isIncorrect">{{ $t('signIn.title') }}</div>
            <div class="form-group">
              <label>{{ $t('signIn.email') }}</label>
              <input type="email" name="email" class="form-control" placeholder="Email" required  maxlength="255" v-model="email"/>
            </div>
            <div class="form-group">
              <div class="input">
                <label>{{ $t('signIn.password') }}</label>
                <input type="password" name="password" class="form-control" required maxlength="255" v-model="password" id="password"/>
                <span v-if="!showPassword" @click="togglePassword" class="eye-sign"><i class="fa fa-lg fa-eye-slash"></i></span>
                <span v-if="showPassword" @click="togglePassword" class="eye-sign"><i class="fa fa-lg fa-eye"></i></span>
              </div>
            </div>
          </div>
          <button class="btn btn-primary" :disabled="isSubmitting">{{ $t('signIn.btn') }}</button>
          <div>
            <a href="#" @click.prevent="toggleRec">{{ $t('signIn.forgotBtn') }}</a>
          </div>
        </form>
        <div v-if="needRec" class="col-md-6 col-md-offset-3 login">
          <div class="login-header">{{ $t('signIn.recovery') }}</div>
          <div class="alert alert-danger" v-if="isIncorrectRec">{{ $t('signIn.recoveryNotFound') }}</div>
          <div class="alert alert-success" v-if="recSuccess">{{ $t('signIn.recoverySent') }}</div>
          <div class="form-group" v-if="!recSuccess">
            <label>{{ $t('signIn.email') }}</label>
            <input type="email" name="email" class="form-control" placeholder="Email" required maxlength="255" v-model="recEmail"/>
          </div>
          <div class="btn-group" v-if="!recSuccess">
            <button class="btn btn-primary" @click="checkRec">{{ $t('signIn.recoverBtn') }}</button>
          </div>
          <div>
            <a href="#" @click.prevent="toggleRec">{{ $t('signIn.recoverSignInBtn') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import registrationModel from './models/registration';
  import userModel from './models/user'
  import MainMenu from './components/ui/MainMenuTmp';
  import commonModel from "./models/common";

  export default {
    name: 'Login',
    components: {
      MainMenu,
    },
    data()
    {
      return {
        recEmail: "",
        email: window._STATE.email,
        recSuccess: false,
        isIncorrectRec: false,
        needRec: false,
        isIncorrect: window._STATE.incorrectLogin,
        showPassword: false,
        isSubmitting: false,
      }
    },
    computed: {
      logoPath() {
        const lang = commonModel.getCurrentLang();
        const logoLang = lang === 'ru' ? 'ru' : 'en';
        return require(`assets/images/itmo-${logoLang}.png`);
      }
    },
    methods: {
      toggleRec()
      {
        this.needRec = !this.needRec;
      },
      togglePassword()
      {
        this.showPassword = !this.showPassword;

        const passField = document.getElementById("password");
        if (this.showPassword) {
          passField.type = "text";
        } else {
          passField.type = "password";
        }
      },
      async checkRec()
      {
        let res = await registrationModel.isEmailRegistered(this.recEmail);
        if(!res.result)
        {
          await userModel.sendRecoveryLink(this.recEmail)
          this.recSuccess = true;
          this.isIncorrectRec = false;
        }
        else
        {
          this.isIncorrectRec = true;
          this.recSuccess = false;
        }
      }
    }

  }

</script>
<style>
  html, body, .login-page, #app
  {
    height: 100%;
  }
  body
  {
    margin-bottom: 0px;
  }

  .login-container
  {
    /*display: flex;
    align-items: center;
    height: 100%;
    height: calc(100% - 53px);
    justify-content: center;
    width: 100%;*/
    margin-top: 150px;
  }
  .logo-container
  {
    position: relative;
  }
  .logo
  {
    margin-bottom: 33px;
    text-align: center;
    position: absolute;
    bottom: 100%;
    left: 50%;
    z-index: -10;
    transform: translateX(-50%);
  }
  .logo img {
    width: 200px;
  }
  .login
  {
    text-align: center;
    padding: 40px 90px;
    background: #fff;
    /*min-width: 540px;*/
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px rgba(208, 215, 221, 0.7);
  }

  .login-header
  {
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: 500;
    color: #1946ba;
  }

  label
  {
    display: block;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #9b9b9b;
  }
  input[type="text"], input[type="email"], input[type="password"]
  {
    height: 52px;
    border-radius: 5px;
    background-color: #ffffff;
    border: solid 1px #d0d7dd;
    font-size: 16px;
    line-height: 1.5;
    color: #323c47;
  }
  .btn-primary
  {
    margin: 25px 0 12px;
    padding: 9px 52px;
  }
  .input
  {
    position: relative;
  }
  .eye-sign
  {
    position: absolute;
    top: 65%;
    right: 10px;
    transform: translate(-50%, -50%);
    color: #9b9b9b;
    user-select: none;
    line-height: 1;
  }
</style>
